import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import JobList from "../components/JobList";

import {
  careersFeaturedContent,
  careersHeroDetails,
} from "../constants";


const Careers = () => {
  const data = useStaticQuery(graphql`
    query {
      featuredContentImages: allFile(
        filter: {
          name: {
            in: ["01-chas-accredited-logo", "03-part-p", "06-nic-eic-logo"]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      heroImage: allFile(
        filter: { name: { in: "hero-brown-books-18th-edition" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

  // advert details
  /* const burnhamFootballClub = SponsorAdverts.find((ad) => {
    return ad.id === "burnham-football-cub";
  });

  const sloughInBloom = SponsorAdverts.find((ad) => {
    return ad.id === "slough-in-bloom";
  });

  const hsPepper = SponsorAdverts.find((ad) => {
    return ad.id === "hs-pepper-co";
  }); */

  // advert images
  /* const burnhamFootballClubImage = data.advertImages.nodes.find((image) => {
    return image.name === "burnham-under-18s-football-club";
  });

  const sloughInBloomImage = data.advertImages.nodes.find((image) => {
    return image.name === "slough-in-bloom-v2";
  });

  const hsPepperImage = data.advertImages.nodes.find((image) => {
    return image.name === "red-cobra-chilli-sauce-v2";
  }); */

   // faqs structured data - to do
   const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - Careers",
        "url":  "https://intact-electrical.co.uk/careers",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = careersHeroDetails;

  return (
    <>
      <Seo       
        title="Careers"
        description="Careers, We help our employees thrive, Contact us, Trainee electrician, Electrical engineer, NIC EIC approved contractor"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={careersFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <JobList />
      {/* <PageAdvert ad={burnhamFootballClub} image={burnhamFootballClubImage} />
      <PageAdvert ad={sloughInBloom} image={sloughInBloomImage} />
      <PageAdvert ad={hsPepper} image={hsPepperImage} /> */}
    </>
  );
};

export default Careers;
