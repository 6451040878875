import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import Headings from "./Headings";

import styles from "../styles/style";


const JobList = () => {
  const data = useStaticQuery(graphql`
    query {
      jobListImages: allFile(
        filter: {
          name: {
            in: [
              "05-alfie-barnard"
              "24-richard-working"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  const traineeElectrician = data.jobListImages.nodes.find((image) => {
    return image.name === "05-alfie-barnard";
  });

  const electricalEngineer = data.jobListImages.nodes.find((image) => {
    return image.name === "24-richard-working";
  });

  //console.log("traineeElectrician: ", traineeElectrician);

  return (
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Headings
          titleTop="Career"
          titleBottom="opportunities"
          description="Soon after founding Intact Electrical, we established ourselves simply with client recommendations. Since then, we have grown rapidly, taking on both commercial contracts and domestic work."
        />
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-6 mt-6 mb-6">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl border ring-1 ring-gray-400">
            <div className="md:flex">
              <div className="md:shrink-0">
                <GatsbyImage
                  image={traineeElectrician.childImageSharp.gatsbyImageData}
                  alt={traineeElectrician.name}
                  className="h-48 w-full object-cover md:h-full md:w-48"
                  loading="lazy"
                />
              </div>
              <div className="pl-4 pr-4 mb-4">
                <div 
                  //className="uppercase text-black-grey-gradient tracking-wide text-sm font-semibold"
                  className={`mt-2 ${styles.headingArticleList}`}
                >
                  Trainee Electrician
                </div>
                <p className="text-quaternary text-sm">
                  We are an ambitious team who are focused on growth, putting
                  our customers at the heart of what we do. We have a great
                  opportunity for a Trainee Electrical Engineer.
                </p>
                <span className="block mt-3 text-sm leading-tight text-quinary font-semibold">
                  position closed
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl border ring-1 ring-gray-400">
            <div className="md:flex">
              <div className="md:shrink-0">
                <GatsbyImage
                  image={electricalEngineer.childImageSharp.gatsbyImageData}
                  alt={electricalEngineer.name}
                  className="h-48 w-full object-cover md:h-full md:w-48"
                  loading="lazy"
                />
              </div>
              <div className="pl-4 pr-4 mb-4">
                <div 
                  //className="uppercase text-black-grey-gradient tracking-wide text-sm font-semibold"
                  className={`mt-2 ${styles.headingArticleList}`}
                >
                  Electrical Engineer
                </div>
                <p className="text-quaternary text-sm">
                Committed to ensuring our clients receive exceptional
                  service. Responsible for carrying out a variety of
                  electrical projects from small fixtures to complex
                  installations.
                </p>
                <span className="block mt-3 text-sm leading-tight text-quinary font-semibold">
                  position closed
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobList;
